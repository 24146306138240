import useApiHelpers from '@/composables/api/helpers';
import useProgressBarActions from '@/composables/progress/useProgressBarActions';

const { updateToComplete } = useProgressBarActions();
const { getRequest, postRequest } = useApiHelpers();
const routes = [
  {
    path: '/identity/dashboard',
    name: 'identity.dashboard',
    component: () => import('@/views/IdentityDashboardView.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/idUpload/:id',
    name: 'identity.upload',
    component: () => import('@/components/forms/Identity/IdentificationUploadForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/identityConfirmation',
    name: 'identity.identityConfirmation',
    component: () => import('@/components/forms/Identity/IdentityConfirmationForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/selfie',
    name: 'identity.selfie',
    component: () => import('@/components/forms/Identity/SelfieForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/cc_selfie',
    name: 'identity.cc.selfie',
    component: () => import('@/components/forms/Identity/CcSelfieForm.vue'),
  },
  {
    path: '/identity/walletOwnership',
    name: 'identity.wallet',
    component: () => import('@/components/forms/Identity/WalletAddressVerification.vue'),
  },
  {
    path: '/identity/purposeOfTransaction',
    name: 'identity.purposeOfTransaction',
    component: () => import('@/components/forms/Identity/PurposeOfTransactionForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/sourceOfFunds',
    name: 'identity.sourceOfFunds',
    component: () => import('@/components/forms/Identity/SourceOfFundsForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/pepSourceOfFunds',
    name: 'identity.pepSourceOfFunds',
    component: () => import('@/components/forms/Identity/PepSourceOfFundsForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/pepSofDocumentUpload',
    name: 'identity.pepSourceOfFundsDocumentUpload',
    component: () => import('@/components/forms/Identity/PepSofDocumentUploadForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/sofDocumentUpload',
    name: 'identity.sofDocumentUpload',
    component: () => import('@/components/forms/Identity/SofDocumentUploadForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/proofOfAddress',
    name: 'identity.proofOfAddress',
    component: () => import('@/components/forms/Identity/ProofOfAddressForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/occupation',
    name: 'identity.occupation',
    component: () => import('@/components/forms/Identity/OccupationForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/sumsub',
    name: 'identity.sumsub',
    component: () => import('@/components/forms/Identity/SumsubForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/plaid',
    name: 'identity.plaid',
    component: () => import('@/components/forms/Identity/PlaidIdentityForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/ssn',
    name: 'identity.ssn',
    component: () => import('@/components/forms/Identity/USTaxIDForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/tnc',
    name: 'identity.tnc',
    component: () => import('@/components/forms/Identity/ZerohashTncForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/dlocal/:type',
    name: 'identity.dlocal',
    component: () => import('@/components/forms/Identity/DlocalIdentityForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/documentNumber',
    name: 'identity.documentNumber',
    component: () => import('@/components/forms/Identity/DocumentNumberForm.vue'),
    beforeEnter() {
      updateToComplete(['verification']);
    },
  },
  {
    path: '/identity/confirm-details',
    name: 'identity.confirm-details',
    component: () => import('@/components/forms/Identity/ConfirmDetailForm.vue'),
  },
  {
    path: '/identity/update-details',
    name: 'identity.update-details',
    component: () => import('@/components/forms/Identity/UpdateDetailForm.vue'),
  },
  {
    path: '/identity/dofp',
    name: 'identity.dofp',
    children: [
      {
        path: 'questionnaire',
        name: 'identity.dofp.questionnaire',
        children: [
          {
            path: 'buy',
            children: [
              {
                path: '1',
                name: 'identity.dofp.questionnaire.buy.1',
                component: () => import('@/components/forms/Identity/Dofp/Buy/QuestionnaireFormOne.vue'),
              },
              {
                path: '2',
                name: 'identity.dofp.questionnaire.buy.2',
                component: () => import('@/components/forms/Identity/Dofp/Buy/QuestionnaireFormTwo.vue'),
              },
              {
                path: '3',
                name: 'identity.dofp.questionnaire.buy.3',
                component: () => import('@/components/forms/Identity/Dofp/Buy/QuestionnaireFormThree.vue'),
              },
            ],
          },
          {
            path: 'sell',
            children: [
              {
                path: '1',
                name: 'identity.dofp.questionnaire.sell.1',
                component: () => import('@/components/forms/Identity/Dofp/Sell/QuestionnaireFormOne.vue'),
              },
              {
                path: '2',
                name: 'identity.dofp.questionnaire.sell.2',
                component: () => import('@/components/forms/Identity/Dofp/Sell/QuestionnaireFormTwo.vue'),
              },
              {
                path: '3',
                name: 'identity.dofp.questionnaire.sell.3',
                component: () => import('@/components/forms/Identity/Dofp/Sell/QuestionnaireFormThree.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'investor-declaration',
        name: 'identity.dofp.investor.declaration',
        component: () => import('@/components/forms/Identity/Dofp/InvestorDeclaration/InvestorDeclarationForm.vue'),
      },
      {
        path: 'cooling-period',
        name: 'cooling.off.period',
        component: () => import('@/views/PaymentCoolingView.vue'),
        async beforeEnter() {
          const { orderId, isRegistered } = await getRequest('/orderId').then(
            (res) => res.data,
          );
          if (orderId !== null && isRegistered) {
            postRequest('/cool-off/notify', { orderId });
          } else {
            postRequest('/cool-off/notify', { orderId: null });
          }
        },
      },
    ],
  },
  {
    path: '/identity/scam-check',
    name: 'identity.scam-check',
    component: () => import('@/components/forms/Identity/ScamCheckQuestionnaireForm.vue'),
  },
];

export default routes;
